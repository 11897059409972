.navbar {
  background-color: #ffffff;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  color: #000000;

}
.sub-menu {
  display: none; /* Initially hide the submenu */
  position: absolute; /* Position it below the Pages menu */
  background-color: white; /* Background color of submenu */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* Optional shadow effect */
  padding: 2px;
}

.nav-item:hover .sub-menu {
  display: block; /* Show submenu on hover */
}

.sub-menu .nav-item {
  padding: 2px; /* Padding for submenu items */
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #03045e;
  width: 100%;
  height: 100px;
  max-width: 1500px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.nav-logo-active {
  background-color: transparent;
}
.nav-logo {
  align-items: center;
  background-color: transparent;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 20px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-items: center;
  color: #03045e;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  background-color: #ffffff;
}

.nav-links {
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 80%;
  border-bottom: 3px solid transparent;
  outline: none;
  color: #03045e;
}

.nav-links:focus,
.nav-links:active {
  outline: none;
  background-color: #ffffff;
  color: #000000;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
  background-color: #ffffff;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
}

.nav-item:hover:after {
  width: 100%;
  background: transparent;
  background-color: #87affa;
}

.nav-item .active {
  color: #000000;
  border: 1px solid transparent;
  background-color: #87affa;
}

.nav-icon {
  display: none;
  color: #000000;
}

.nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #ffffff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    background-color: #ffffff;
  }

  .nav-menu.active {
    background-color: #ffffff;
    left: 0px;
    opacity: 1;
    z-index: 1;
  }

  .nav-item .active {
    color: #000000;
    border: none;
  }

  .nav-links {
    padding: 0.3rem;
    width: 100%;
    display: block;
    color: #000000;
  }

  .nav-logo .icon {
    width: 2rem;
    height: 2rem;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #000000;
  }
}